.navbar {
	width: 100%;
	height: 100px;
	background-color: #121619;
	display: flex;
	flex-direction: row;
  }
  
  .navbar .left-side {
	flex: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 50px;
  }
  
  .navbar .left-side p {
	width: 70px;
  }
  
  .navbar .right-side {
	flex: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: right;
  }
  
  .navbar a {
	color: white;
	text-decoration: none;
	margin: 20px;
  }
  
  .navbar .right-side button {
	background-color: transparent;
	border: none;
	color: white;
	cursor: pointer;
  }
  
  .navbar .right-side svg {
	font-size: 40px;
  }
  
  .navbar #open {
	padding-left: 0px;
  }
  .navbar #open p {
	display: none;
  }
  
  .navbar #close p {
	display: inherit;
  }
  
  .navbar #open .hiddenLinks {
	display: inherit;
	margin-left: 30px;
  }
  
  .navbar #close .hiddenLinks {
	display: none;
  }
  
  .navbar #open a {
	width: 70px;
	margin: 5px;
  }
  @media only screen and (max-width: 900px) {
	.navbar .right-side a {
	  width: 70px;
	}
	.navbar .left-side {
	  padding-left: 50px;
	}
  }
  
  @media only screen and (max-width: 600px) {
	.navbar .right-side a {
	  display: none;
	}
  
	.navbar .right-side {
	  justify-content: flex-end;
	  padding-right: 50px;
	}
  
	.navbar .right-side button {
	  display: inherit;
	}
  }
  
  @media only screen and (min-width: 600px) {
	.navbar .right-side button {
	  display: none;
	}
	.navbar .left-side p{
		display: show;
	}
	.hiddenLinks {
	  display: none;
	}
  }
  